import { useState, useEffect, useRef } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormLabel,
  FormControl,
  Input,
  Spinner,
  Text,
  Stack,
  Textarea,
  Select,
  Checkbox,
  Flex,
  Spacer,
  useColorMode,
  Tooltip,
} from "@chakra-ui/react";
import { Select as MultiSelect } from "chakra-react-select";
import { CustomToast } from "@/common/toast/CustomToast";
import { ISelectOption } from "@/interfaces/ISelectOption";
import {
  IEverbridgeRiskEvent,
  IEverbridgeRiskEventModalProps,
} from "@/interfaces/IEverbridgeIntegration";
import { useEverbridgeIntegration } from "./everbridge-integration-context";
import GeoInput from "../query-builder/layer-inputs/geo-input";
import { MiniPost } from "@/components/feeds/mini-post";
import { errorMessage } from "@/common/utility/errorMessage";
import { fetchFormValues, fetchRiskEvent } from "@/services/useEverbridge";
import { useLanguage } from "../userprofile/language/lang-context";

const initialEverbridgeRiskEvent: IEverbridgeRiskEvent = {
  eventId: "",
  postId: "",
  searchId: "",
  status: "Actual",
  urgency: "",
  type: "",
  severity: "",
  scope: "Public",
  certainty: "",
  headline: "",
  web: "",
  description: "",
  categories: [],
  subCategory: null,
  areaDescription: "",
  circle: "",
  includePostUrl: false,
  allowDerivedLocation: false,
  postedDate: new Date(),
};

const initializeCircle = {
  circleCenter: null,
  circleRadius: null,
};

const initializeMapData = {
  mapCenter: {
    lat: 25,
    lng: 25,
  },
  zoom: 2,
};

const rectangle = {
  rectangleAreaInKm: null,
  rectangleBounds: null,
};

const EverbridgeIntegrationModal = ({
  data,
  post,
  isOpen,
  onOpen,
  onClose,
  modalResponse,
}: IEverbridgeRiskEventModalProps) => {
  const {
    state: { fetch, everbridge },
    onSave,
  } = useEverbridgeIntegration();
  const {
    state: { lang },
  } = useLanguage();
  const { successToast, warningToast } = CustomToast();
  const { colorMode } = useColorMode();
  const isFirstRender = useRef(true);
  const isFirstRenderFormValues = useRef(true);
  const [isNew, setIsNew] = useState(true);
  const [selectedCategories, setSelectedCategories] = useState<ISelectOption[]>(
    []
  );
  const [selectedSubCategories, setSelectedSubCategories] = useState<
    ISelectOption[]
  >([]);
  const [subCategories, setSubCategories] = useState<any>();

  const [circle, setCircle] = useState(initializeCircle);
  const [mapData, setMapData] = useState(initializeMapData);
  const [isInvalid, setIsInvalid] = useState(false);
  const [formValues, setFormValues] = useState<any>(null);
  const [everbridgeRiskEvent, setEverbridgeRiskEvent] =
    useState<IEverbridgeRiskEvent>(initialEverbridgeRiskEvent);
  const [existingRiskEvent, setExistingRiskEvent] = useState<any>(null);
  const [mapCenter, setMapCenter] = useState({ lat: 25, lng: 25 });
  const [mapRadius, setMapRadius] = useState(null);
  const [mapCircle, setMapCircle] = useState<any>(null);
  const [hasNoSubCategory, setHasNoSubCategory] = useState(false);
  const [hasDerivedLocation, setHasDerivedLocation] = useState(false);
  const [hasGeoCircle, setHasGeoCircle] = useState(false);

  const isDisabled =
    !selectedCategories ||
    (selectedCategories && selectedCategories.length === 0) ||
    (!selectedSubCategories && !hasNoSubCategory) ||
    (selectedSubCategories && selectedSubCategories.length === 0) ||
    everbridgeRiskEvent.headline === "" ||
    everbridgeRiskEvent.description === "" ||
    (!selectedCategories &&
      !selectedSubCategories &&
      !hasGeoCircle &&
      !everbridgeRiskEvent.allowDerivedLocation) ||
    (!selectedCategories &&
      !selectedSubCategories &&
      !hasGeoCircle &&
      everbridgeRiskEvent.areaDescription === "");

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;

      const loadRiskEvent = async () => {
        let defaultFormValues = null;
        defaultFormValues = await fetchFormValues();
        if (defaultFormValues) {
          setFormValues(defaultFormValues);
        }

        if (post) {
          const locations = post?.namedEntities?.locations;
          if (locations && locations.length > 0) {
            const derivedLocations = locations.filter(
              (x) => x.geoNameId !== "-"
            );
            if (derivedLocations && derivedLocations.length > 0) {
              setHasDerivedLocation(true);
            }
          }
        } else if (data) {
          setHasDerivedLocation(true);
        }

        let manualRiskEvent = null;
        if (post && post.searchUid && post.uniqueId) {
          manualRiskEvent = await fetchRiskEvent(post.searchUid, post.uniqueId);
          if (manualRiskEvent && manualRiskEvent.id) {
            setExistingRiskEvent(manualRiskEvent);
          }
        }

        setIsNew(Boolean(data && data.postId) ? false : true);
        if (manualRiskEvent && manualRiskEvent?.id) {
          const riskEventJson = JSON.parse(manualRiskEvent.riskEventJson);
          const riskEventInfo = riskEventJson.info[0];

          if (riskEventJson && riskEventInfo) {
            const area = riskEventInfo.area[0];
            const circle = area.circle[0];
            const longitude = circle.split(",")[0] || 0;
            const latitude = circle.split(" ")[1] || 0;
            const radiusInKm = circle.split(" ")[2] || 0;
            const radius = area.radius || radiusInKm * 100;
            setMapCenter({ lat: Number(latitude), lng: Number(longitude) });
            setMapCircle({ lat: Number(latitude), lng: Number(longitude) });
            setMapRadius(radius);

            const selectedCategory =
              riskEventInfo.category?.length > 0
                ? riskEventInfo.category[0]
                : null;
            if (selectedCategory) {
              const category = {
                label: selectedCategory,
                value: selectedCategory,
              };

              setSelectedCategories([category]);
            }

            const selectedSubCategory =
              riskEventInfo.parameter?.length > 0
                ? riskEventInfo.parameter[0].value
                : null;
            if (selectedSubCategory) {
              const subCategory = {
                label: selectedSubCategory,
                value: selectedSubCategory,
              };

              setSelectedSubCategories([subCategory]);
            }

            const riskEvent: IEverbridgeRiskEvent = {
              eventId: manualRiskEvent.eventId,
              postId: manualRiskEvent.postId,
              searchId: manualRiskEvent.searchId,
              status: riskEventJson.status,
              urgency: riskEventInfo.urgency,
              type: riskEventJson.msgType,
              severity: riskEventInfo.severity,
              scope: riskEventJson.scope,
              certainty: riskEventInfo.certainty,
              headline: riskEventInfo.headline,
              web: riskEventInfo.web,
              description: riskEventInfo.description,
              categories: riskEventInfo.category,
              subCategory: selectedSubCategory,
              areaDescription: area.areaDesc,
              circle: circle,
              includePostUrl: false,
              allowDerivedLocation: false,
              isPost: true,
              postedDate: riskEventInfo.postedDate || new Date(),
              postUniqueId: post?.uniqueId,
            };
            setEverbridgeRiskEvent(riskEvent);
          }
        } else if (data) {
          const riskEvent: IEverbridgeRiskEvent = {
            eventId: data.eventId,
            postId: data.postId,
            searchId: data.searchId,
            status: "Actual",
            urgency: data.urgency
              ? data.urgency
              : defaultFormValues?.urgencyValues[0],
            type: data.type ? data.type : defaultFormValues?.typeValues[0],
            severity: data.severity
              ? data.severity
              : defaultFormValues?.severityValues[0],
            scope: "Public",
            certainty: data.certainty
              ? data.certainty
              : defaultFormValues?.certaintyValues[0],
            headline: data.headline,
            web: data.web,
            description: data.description,
            categories: data.categories || [],
            subCategory: data.subCategory,
            areaDescription: data.areaDescription,
            circle: data.circle,
            includePostUrl: data.includePostUrl,
            allowDerivedLocation: data.allowDerivedLocation,
            isPost: data.isPost,
            postedDate: data.postedDate,
            postUniqueId: data.postUniqueId,
          };
          setEverbridgeRiskEvent(riskEvent);

          const selectedCategory =
            data.categories?.length > 0 ? data.categories[0] : null;
          if (selectedCategory) {
            const category = {
              label: selectedCategory,
              value: selectedCategory,
            };

            setSelectedCategories([category]);
          }

          const selectedSubCategory = data.subCategory;
          if (selectedSubCategory) {
            const subCategory = {
              label: selectedSubCategory,
              value: selectedSubCategory,
            };

            setSelectedSubCategories([subCategory]);
          }

          const map = data.map;
          if (map) {
            setMapCenter({
              lat: Number(map.latitude),
              lng: Number(map.longitude),
            });
            setMapCircle({
              lat: Number(map.latitude),
              lng: Number(map.longitude),
            });
            setMapRadius(map.radius);
          }
        } else {
          setEverbridgeRiskEvent(initialEverbridgeRiskEvent);
        }
      };
      loadRiskEvent();
    }
  }, [data, post]);

  useEffect(() => {
    if (selectedCategories && selectedCategories.length > 0) {
      const category = selectedCategories[0];
      if (category.value === "Wildfires") {
        setHasNoSubCategory(true);
        setSubCategories(null);
      } else {
        setHasNoSubCategory(false);
        const subCategoryItems = formValues?.subCategoryValues?.find(
          (x) => x.category === category.value
        );
        if (subCategoryItems && subCategoryItems.subCategory?.length > 0) {
          setSubCategories(subCategoryItems.subCategory);
        }
      }
    }
  }, [selectedCategories, formValues]);

  const updateEverbridgeRiskEventState = (
    property: keyof IEverbridgeRiskEvent,
    value: any
  ) => {
    setEverbridgeRiskEvent((prevState) => {
      return {
        ...prevState,
        [property]: value,
      };
    });
  };

  const handleCategories = (selected: ISelectOption[]) => {
    if (selected.length === 0) {
      setSelectedCategories(null);
      setSelectedSubCategories(null);
    } else {
      const selectedItem = selected[selected.length - 1];
      if (selectedItem) {
        const category = {
          label: selectedItem.label,
          value: selectedItem.value,
        };

        setSelectedCategories([category]);
        setSelectedSubCategories(null);
        updateEverbridgeRiskEventState("categories", [category.value]);
        updateEverbridgeRiskEventState("subCategory", null);
      }
    }
  };

  const handleSubCategory = (selected: ISelectOption[]) => {
    if (selected.length === 0) {
      setSelectedSubCategories(null);
    } else {
      const selectedItem = selected[selected.length - 1];
      if (selectedItem) {
        const subCategory = {
          label: selectedItem.label,
          value: selectedItem.value,
        };

        setSelectedSubCategories([subCategory]);
        updateEverbridgeRiskEventState("subCategory", subCategory.value);
      }
    }
  };

  const handleCircle = (data: any) => {
    const geoCircle = data?.map?.geo?.circle;
    if (geoCircle) {
      const latitude = geoCircle.center?.latitude;
      const longitude = geoCircle.center?.longitude;
      const radius = geoCircle.radius;
      const radiusInKm = radius / 1000;

      setCircle({
        circleCenter: {
          lat: latitude,
          lng: longitude,
        },
        circleRadius: radius,
      });

      if (longitude && latitude && radius) {
        const circle = `${longitude}, ${latitude} ${radiusInKm}`;
        updateEverbridgeRiskEventState("circle", circle);
        updateEverbridgeRiskEventState("radius", radius);
      }
    } else {
      setCircle(initializeCircle);
      updateEverbridgeRiskEventState("circle", "");
    }
  };

  const handleIncludePostLink = (e) => {
    if (e.target.checked) {
      if (everbridgeRiskEvent.description !== "") {
        updateEverbridgeRiskEventState(
          "description",
          `${everbridgeRiskEvent.description}\n----------------------------\n${everbridgeRiskEvent.web}`
        );
      } else {
        updateEverbridgeRiskEventState("description", everbridgeRiskEvent.web);
      }
    } else {
      updateEverbridgeRiskEventState("description", "");
    }
  };

  const save = async () => {
    if (everbridgeRiskEvent.isPost) {
      let riskEvent = everbridgeRiskEvent;
      if (riskEvent.eventId === "") {
        let description = riskEvent.description;
        if (riskEvent.postId) {
          const protocol = window.location.protocol.includes("https")
            ? "https://"
            : "http://";
          const currentURL = `${protocol}${window.location.host}`;
          description += `\n----------------------------\nView in signal: ${currentURL}${"/postview?uid="}${
            riskEvent.postUniqueId
          }`;

          riskEvent = {
            ...riskEvent,
            description: description,
          };
        }
      }

      const res = await onSave(riskEvent);
      if (res?.status === 200) {
        if (!fetch) {
          successToast(
            "Risk Event Settings",
            "The risk event was successfully created."
          );
          modalResponse(everbridgeRiskEvent);
          onClose();
        }
      } else {
        modalResponse(null);
      }
    } else {
      //pass data to query builder search
      const riskEvent = {
        status: "Actual",
        urgency: everbridgeRiskEvent.urgency,
        type: everbridgeRiskEvent.type,
        severity: everbridgeRiskEvent.severity,
        scope: "Public",
        certainty: everbridgeRiskEvent.certainty,
        headline: everbridgeRiskEvent.headline,
        web: everbridgeRiskEvent.web,
        description: everbridgeRiskEvent.description,
        categories: everbridgeRiskEvent.categories,
        subCategory: everbridgeRiskEvent.subCategory,
        areaDescription: everbridgeRiskEvent.areaDescription,
        allowDerivedLocation: everbridgeRiskEvent.allowDerivedLocation,
        circle: everbridgeRiskEvent.circle,
        map: {
          latitude: circle?.circleCenter?.lat,
          longitude: circle?.circleCenter?.lng,
          radius: circle?.circleRadius,
          radiusInKm: circle?.circleRadius ? circle?.circleRadius / 1000 : 0,
        },
        postedDate: everbridgeRiskEvent.postedDate || new Date(),
      };

      const json = JSON.stringify(riskEvent);
      modalResponse(json);
      onClose();
    }
  };

  const handleDelete = () => {
    warningToast(
      "",
      "Risk Event Settings will be deleted after you save your search."
    );

    setTimeout(() => {
      setEverbridgeRiskEvent(initialEverbridgeRiskEvent);
      modalResponse("");
      onClose();
    }, 2000);
  };

  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen={isOpen}
      onClose={onClose}
      size={"4xl"}
    >
      <ModalOverlay />
      <ModalContent fontSize="sm">
        <ModalHeader
          display="flex"
          alignItems="center"
          justifyContent="center"
          p={1}
          fontSize="md"
        >
          {lang?.EVERBRIDGE_RISK_EVENT_SETTINGS || "Risk Event Settings"}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={1} fontSize="xs">
          {post?.isDuplicate ? (
            <Text fontWeight={"bold"}>[This post is a duplicate]</Text>
          ) : null}
          {(data?.isPost || existingRiskEvent) && <MiniPost post={post} />}
          <Stack
            spacing={6}
            direction={{ base: "column", md: "row" }}
            mt={1}
            flexDirection="row"
          >
            <FormControl>
              <FormLabel> {lang?.EVERBRIDGE_URGENCY || "Urgency"}</FormLabel>
              <Select
                size="xs"
                placeholder="Select value"
                onChange={(e) =>
                  updateEverbridgeRiskEventState("urgency", e.target.value)
                }
                value={everbridgeRiskEvent.urgency}

              >
                {formValues?.urgencyValues?.map((urgency: string) => (
                  <option key={urgency} value={urgency}>
                    {urgency}
                  </option>
                ))}
              </Select>
            </FormControl>
            <FormControl>
              <FormLabel> {lang?.EVERBRIDGE_MSGTYPE || "Type"}</FormLabel>
              <Select
                size="xs"
                onChange={(e) =>
                  updateEverbridgeRiskEventState("type", e.target.value)
                }
                value={everbridgeRiskEvent.type}

              >
                {formValues?.typeValues?.map((type: string) => (
                  <option key={type} value={type}>
                    {type}
                  </option>
                ))}
              </Select>
            </FormControl>
          </Stack>
          <Stack spacing={6} direction={{ base: "column", md: "row" }} mt={1}>
            <FormControl>
              <FormLabel> {lang?.EVERBRIDGE_SEVERITY || "Severity"}</FormLabel>
              <Select
                size="xs"
                placeholder="Select value"
                onChange={(e) =>
                  updateEverbridgeRiskEventState("severity", e.target.value)
                }
                value={everbridgeRiskEvent.severity}

              >
                {formValues?.severityValues?.map((severity: string) => (
                  <option key={severity} value={severity}>
                    {severity}
                  </option>
                ))}
              </Select>
            </FormControl>
            <FormControl>
              <FormLabel> {lang?.EVERBRIDGE_CERTAINTY || "Certainty"}</FormLabel>
              <Select
                size="xs"
                placeholder="Select value"
                onChange={(e) =>
                  updateEverbridgeRiskEventState("certainty", e.target.value)
                }
                value={everbridgeRiskEvent.certainty}

              >
                {formValues?.certaintyValues?.map((certainty: string) => (
                  <option key={certainty} value={certainty}>
                    {certainty}
                  </option>
                ))}
              </Select>
            </FormControl>
          </Stack>
          <FormControl mt={1} p={0} isRequired>
            <FormLabel> {lang?.EVERBRIDGE_CATEGORY || "Category"}</FormLabel>
            <MultiSelect
              size="sm"
              variant="outline"
              placeholder={lang?.EVERBRIDGE_CATEGORIES_PH || "Select Category"}
              isMulti
              value={selectedCategories}

              options={formValues?.categoryValues.map((category: string) => ({
                value: category,
                label: category,
              }))}
              onChange={handleCategories}
            ></MultiSelect>
            {errorMessage(true, "categories")}
          </FormControl>
          {selectedCategories?.length > 0 && !hasNoSubCategory && (
            <FormControl mt={1} p={0} isRequired>
              <FormLabel>{lang?.EVERBRIDGE_SUB_CATEGORY || "Sub-Category"}</FormLabel>
              <MultiSelect
                size="sm"
                variant="outline"
                placeholder={lang?.EVERBRIDGE_SELECT_SUB_CATEGORY || "Select Sub-Category"}
                isMulti
                value={selectedSubCategories}

                options={subCategories?.map((subCategory: string) => ({
                  value: subCategory,
                  label: subCategory,
                }))}
                onChange={handleSubCategory}
              ></MultiSelect>
              {errorMessage(true, "subCategories")}
            </FormControl>
          )}
          <FormControl mt={1} isRequired>
            <FormLabel> {lang?.EVERBRIDGE_HEADLINE || "Headline"}</FormLabel>
            <Input
              p={1}
              size="xs"
              type="text"
              onChange={(e) =>
                updateEverbridgeRiskEventState("headline", e.target.value)
              }
              value={everbridgeRiskEvent.headline}

            />
            {errorMessage(true, "headline")}
          </FormControl>
          <FormControl isRequired mt={1}>
            <Flex>
              <FormLabel> {lang?.EVERBRIDGE_DESC || "Event description"}</FormLabel>
              <Spacer width={"50%"} />
              <Checkbox
                size="sm"
                onChange={handleIncludePostLink}

              >
                {lang?.EVERBRIDGE_POST_LINK_INCLUDE || "Include post link?"}
              </Checkbox>
            </Flex>
            <Textarea
              p={1}
              maxLength={50000}
              size="sm"
              onChange={(e) =>
                updateEverbridgeRiskEventState("description", e.target.value)
              }
              value={everbridgeRiskEvent.description}

            />
            <Flex justify="flex-end" fontSize={"xs"}>
              {everbridgeRiskEvent.description?.length}/50000
            </Flex>
            {errorMessage(true, "event description")}
          </FormControl>
          <FormControl mt={-1} isRequired={circle.circleCenter}>
            <FormLabel> {lang?.EVERBRIDGE_AREA_DESC || "Area description"}</FormLabel>
            <Input
              size="xs"
              type="text"
              onChange={(e) =>
                updateEverbridgeRiskEventState(
                  "areaDescription",
                  e.target.value
                )
              }
              value={everbridgeRiskEvent.areaDescription}

            />
          </FormControl>
          <FormControl
            mt={1}
            isRequired={!everbridgeRiskEvent.allowDerivedLocation}
          >
            <Flex>
              <FormLabel> {lang?.EVERBRIDGE_GEOCIRCLE || "Geo Circle"}</FormLabel>
              <Spacer width={"50%"} />
              {hasDerivedLocation ? (
                <Checkbox
                  size="sm"
                  onChange={(e) =>
                    updateEverbridgeRiskEventState(
                      "allowDerivedLocation",
                      e.target.checked
                    )
                  }
                  isChecked={everbridgeRiskEvent.allowDerivedLocation}

                >
                  {lang?.EVERBRIDGE_LOCATION_DERIVED || "Use derived location?"}
                </Checkbox>
              ) : (
                <Tooltip
                  aria-label="No derived location available"
                >
                  <Checkbox
                    isDisabled
                    size="sm"
                    onChange={(e) =>
                      updateEverbridgeRiskEventState(
                        "allowDerivedLocation",
                        e.target.checked
                      )
                    }
                    isChecked={everbridgeRiskEvent.allowDerivedLocation}

                  >
                      {lang?.EVERBRIDGE_LOCATION_DERIVED || "Use derived location?"} [No derived location available]
                  </Checkbox>
                </Tooltip>
              )}
            </Flex>
            {mapCircle && (
              <GeoInput //show location when circle, radius and center are loaded async
                width={850}
                height={250}
                circleCenter={mapCircle}
                circleRadius={mapRadius}
                mapCenter={mapCenter}
                rectangleBounds={rectangle.rectangleBounds}
                zoom={mapData.zoom}
                drawingMode={"circle"}
                circleMaxRadius={39999}
                rectangleMaxWidth={39999}
                onChange={(data: any) => {
                  handleCircle(data);
                  if(data?.map?.geo?.circle)                  
                    setHasGeoCircle(true);
                  else
                    setHasGeoCircle(false);
                }}
              />
            )}
            {!mapCircle && (
              <GeoInput //show by default
                width={850}
                height={250}
                circleCenter={mapCircle}
                circleRadius={mapRadius}
                mapCenter={mapCenter}
                rectangleBounds={rectangle.rectangleBounds}
                zoom={mapData.zoom}
                drawingMode={"circle"}
                circleMaxRadius={39999}
                rectangleMaxWidth={39999}
                onChange={(data: any) => {
                  handleCircle(data);
                  if(data?.map?.geo?.circle)                  
                    setHasGeoCircle(true);
                  else
                    setHasGeoCircle(false);
                }}
              />
            )}
            {errorMessage(true, "geo circle")}
          </FormControl>
        </ModalBody>

        <ModalFooter>
          {!everbridgeRiskEvent.isPost && (
            <Button
              size="sm"
              colorScheme="red"
              mr={3}
              onClick={handleDelete}
              isDisabled={isDisabled}
            >
              {lang?.TAGS_DELETE || "Delete"}
            </Button>
          )}
          <Button
            size="sm"
            colorScheme="messenger"
            mr={3}
            isLoading={fetch}
            loadingText="Submitting..."
            onClick={save}
            isDisabled={isDisabled || (!hasGeoCircle && !everbridgeRiskEvent.allowDerivedLocation)}
          >
            {lang?.SAVE || "Save"}
          </Button>
          <Button size="sm" colorScheme="gray" onClick={() => onClose()}>
            {lang?.CANCEL || "Cancel"}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default EverbridgeIntegrationModal;
